<template>
  <component
    :is="componentTag"
    :href="link"
    :disabled="disabled || busy"
    :type="type"
    :class="[generalClasses, colorClasses]"
    :data-testid="componentTag"
  >
    <span class="flex justify-center items-center" :class="{ relative: busy }">
      <LoadingIcon
        v-if="busy"
        class="absolute"
        :class="compact || square ? 'w-4 h-4' : 'w-5 h-5'"
      />
      <span
        :class="[compact || square ? 'space-x-2' : 'space-x-3', { invisible: busy }]"
        class="flex items-center"
      >
        <slot name="button"></slot>
      </span>
      <ArrowRightIcon v-if="hasArrow" class="ml-2 h-5 w-5" />
    </span>
  </component>
</template>

<script setup>
import { computed } from "vue"
import Link from "@/components/elements/links/Link.vue"
import { LoadingIcon } from "@/components/icons"
import { ArrowRightIcon } from "@heroicons/vue/20/solid"

const props = defineProps({
  compact: Boolean,
  square: Boolean,
  disabled: Boolean,
  busy: Boolean,
  href: String,
  type: {
    type: String,
    default: "button",
  },
  isExternalLink: Boolean,
  hasArrow: Boolean,
  buttonType: {
    type: String,
    default: "primary",
    validator: value => ["primary", "secondary", "tertiary", "success", "danger"].includes(value),
  },
})

const componentTag = computed(() => {
  if (!!props.href && (props.isExternalLink || props.disabled || props.busy)) return "a"
  if (props.href) return "Link"
  return "button"
})
const link = computed(() => {
  if (!props.href) return
  return props.disabled || props.busy ? "javascript:void(0)" : props.href
})
const generalClasses = computed(() => {
  return {
    "inline-flex justify-center items-center uppercase tracking-widest leading-4 font-semibold rounded transition-colors duration-300 ease-in-out": true,
    "h-9 w-9 p-2.5 text-2xs": props.square,
    "h-9 py-2.5 px-4 text-2xs": props.compact,
    "h-12 py-3.5 px-6 text-xs": !props.square && !props.compact,
    "focus:outline-none focus:ring-2 focus:ring-offset-2": !props.disabled,
    "cursor-not-allowed": props.disabled,
    "cursor-wait": props.busy,
  }
})
const colorClasses = computed(() => {
  switch (props.buttonType) {
    case "primary":
      return {
        "text-primary-on bg-primary hover:bg-primary-hover focus:ring-primary":
          !props.disabled && !props.busy,
        "text-primary bg-primary-disabled hover:bg-primary-disabled": props.disabled || props.busy,
      }
    case "secondary":
      return {
        "text-white bg-black hover:bg-gray-700 focus:ring-black": !props.disabled && !props.busy,
        "text-gray-400 bg-gray-700 hover:bg-gray-700": props.disabled || props.busy,
      }
    case "tertiary":
      return {
        "text-gray-600 bg-gray-100 hover:bg-gray-300 focus:ring-gray-100":
          !props.disabled && !props.busy,
        "text-gray-400 bg-gray-100 hover:bg-gray-100": props.disabled || props.busy,
      }
    case "success":
      return {
        "text-white bg-green-300 hover:bg-green-500 focus:ring-green-300":
          !props.disabled && !props.busy,
        "text-green-500 bg-green-50 hover:bg-green-50": props.disabled || props.busy,
      }
    case "danger":
      return {
        "text-red-300 hover:bg-red-50 hover:text-red-500 focus:ring-red-300":
          !props.disabled && !props.busy,
        "text-red-200": props.disabled || props.busy,
      }
  }
})
</script>
<script>
export default {
  components: {
    Link,
  },
}
</script>
